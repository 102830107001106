/* 

Estimated Colors:
Primary (Dark Blue/Navy): #1B2653
Secondary (Dark Gray/Black for text): #333333
White (Background & Text Areas): #FFFFFF
Light Gray (Subtle Background Elements): #F5F5F5
Accent (Blue for CTA Buttons): #3452FF
Hover/Active States (Darker Blue Variant): #233E9F
Fonts Used (Estimated)
The typography looks modern and professional, possibly using:
Headings: Poppins, Montserrat, or DM Sans
Body Text: Inter, Roboto, or Open Sans
Buttons/Text Links: Likely bold variants of the heading font


*/

/* .ant-btn-variant-solid {
  background-color: var(--ant-primary-color) !important;
  border: var(--ant-primary-color) !important ;
  color: #fff !important;
}

.ant-badge .ant-badge-count {
  background-color: var(--ant-primary-color) !important;
} */

/* :root {
  --ant-primary-color: #1b2653;
  --ant-primary-color-hover: #233e9f;
  --ant-primary-color-active: #1a2e73;
  --ant-primary-color-outline: rgba(52, 82, 255, 0.2);
} */

.search-bar {
  max-width: 600px;
  margin: auto;
}

.ant-layout-sider {
  background-color: #fff !important;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: sans-serif !important;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-table {
  width: 100%;
  overflow-x: auto;
}

.ant-table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.ant-table-content {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .ant-table {
    font-size: 12px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    white-space: nowrap;
  }

  .ant-table-container {
    overflow-x: auto;
    display: block;
  }
}

.space-align-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
}
.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

/* .ant-menu-item-selected {
  background-color: #3452ff !important;
  color: white !important;
} */

.ant-table-cell
{
  text-transform: capitalize;
}


.ant-menu-dark.ant-menu-root.ant-menu-inline {
  border-inline-end: 0px solid rgba(5, 5, 5, 0.06);
  min-height: 120vh;
}


 .ant-layout-sider-has-trigger{
  padding-bottom: 0px !important;
}

/* Global thin scrollbar styles */
* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #bfbfbf transparent; /* Firefox scrollbar thumb and track color */
}

*::-webkit-scrollbar {
  width: 4px; /* Thin scrollbar width */
}

*::-webkit-scrollbar-thumb {
  background-color: #bfbfbf; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded thumb edges */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Transparent scrollbar track */
}

/* Make all Ant Design tables responsive */
.ant-table {
  width: 100%;
  overflow-x: auto;
}

 
@media (max-width: 768px) {
  .ant-table-wrapper {
    overflow-x: auto;
  }

  .ant-table {
    min-width: 600px;  
  }
}



/* .full-screen-modal .ant-modal,
.full-screen-modal .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
}

.full-screen-modal .ant-modal-body {
  height: calc(100vh - 0px);
} */

